<template>
  <div id="app">
    <keep-alive include="mall">
      <router-view class="child-view"></router-view>
    </keep-alive>
    <!-- <my-footer :flag="flag"></my-footer> -->
    <van-tabbar v-if="show" v-model="active" active-color="#F8892F" inactive-color="#999">
      <van-tabbar-item @click="pushPage('/venue')">
        <span>全景</span>
        <template #icon="props">
          <img class="img" v-if="props.active" src="./assets/image/全景@2x.png" />
          <img class="img" v-else src="./assets/image/全景.png" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item @click="pushPage('/panorama')">
        <span>场馆</span>
        <template #icon="props">
          <img class="img" v-if="props.active" src="./assets/image/场景@2x.png" />
          <img class="img" v-else src="./assets/image/场景.png" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item @click="pushPage('/appointment')">
        <span>预约</span>
        <template #icon="props">
          <img class="img" v-if="props.active" src="./assets/image/我的@2x.png" />
          <img class="img" v-else src="./assets/image/我的.png" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
// import myFooter from '@/components/footer.vue'
export default {
  // components: {
  //   'my-footer': myFooter
  // },
  data() {
    return {
      show: true,
      active: 0
    }
  },
  created() {
    // localStorage.setItem('token', '')
    if (this.$route.path === '/appointment') {
      this.active = 2
    } else if (this.$route.path === '/panorama') {
      this.active = 1
    } else if (this.$route.path === '/venue') {
      this.active = 0
    }
  },
  watch: {
    $route(to, from) {
      const title = localStorage.getItem('title')
      if (to.meta.title && to.name !== 'textfield') {
        document.title = to.meta.title
      } else if (to.name === 'textfield') {
        document.title = title
      } else {
        document.title = 'VR'
      }

      if (
        to.name !== 'appointment' &&
        to.name !== 'venue' &&
        to.name !== 'panorama'
      ) {
        this.show = false
      } else {
        this.show = true
      }
      if (to.name === 'venue') {
        console.log(1111)
        this.active = 0
      } else if (to.name === 'panorama') {
        this.active = 1
      } else if (to.name === 'appointment') {
        this.active = 2
      }
    }
  },
  methods: {
    pushPage(path) {
      localStorage.setItem('flag', 0)
      this.$router.replace(path)
    }
  }
}
</script>

<style lang="less">
.img {
  height: 23px;
  width: 23px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: #f9f9f9;
  // height: 100vh;
}
body,
html {
  background-color: #f9f9f9;
}
</style>
