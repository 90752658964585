<template>
  <div>
    <div v-if="phone" class="content">
      <div class="top">
        <div class="t-box">
          <img v-if="img" class="headimg" :src="img" alt />
          <img v-else class="headimg" src="@/assets/image/默认.png" alt />
          <div v-if="flag">
            <div class="name-box" @click="goinform(img,phone,companyName,username)">
              <span class="name">{{username}}</span>
              <img src="@/assets/image/编辑.png" alt />
            </div>
            <div class="phone">
              手机号：
              <span>{{phone}}</span>
            </div>
            <div class="phone">
              单位/公司：
              <span>{{companyName}}</span>
            </div>
          </div>
          <div class="notoken" v-else @click="gologin">登录/注册</div>
        </div>
        <div class="b-box" v-if="isReviewer">
          <div class="audit" @click="gosh">认证审核</div>
          <div @click="goyy">预约审核</div>
        </div>
        <img v-if="authStatus===1?true:false" src="@/assets/image/审核中.png" alt />
        <img v-else-if="authStatus===3?true:false" src="@/assets/image/通过.png" alt />
        <img v-else @click="goAuthentication" src="@/assets/image/未认证.png" alt />
      </div>
      <div class="opinion">
        <div @click="goRecord">
          <img src="@/assets/image/记录.png" alt />
          <div>
            <div>意见记录</div>
            <span>我提交的意见</span>
          </div>
        </div>
        <div class="center"></div>
        <div @click="goSubmit">
          <img src="@/assets/image/发表.png" alt />
          <div>
            <div>发表意见</div>
            <span>发表我的意见/反馈</span>
          </div>
        </div>
      </div>
      <div class="all">
        <div class="all-top">
          <div class="yellow"></div>
          <span>我的预约记录</span>
        </div>
        <div class="bom" @click="goaudit">
          <span class="all-bom">全部</span>
          <img src="@/assets/image/向左.png" alt />
        </div>
      </div>
      <van-empty v-if="!show" description="暂无预约记录" />
      <van-list
        v-else
        :immediate-check="true"
        v-model="loading"
        :finished="finished"
        :finished-text="finishedtext"
        @load="onLoad"
      >
        <div v-for="item in data" :key="item.id" class="bottom" @click="goBookingdetails(item.id)">
          <div class="bottom-top">
            <div>
              订单编号：
              <span>{{item.id}}</span>
            </div>
            <div
              class="sta"
              :id="item.orderStatus===0?'':item.orderStatus===2?'orange':item.orderStatus===1?'':'black'"
            >{{item.orderStatus===0?'待审核':item.orderStatus===2?'已审核通过':item.orderStatus===1?'待审核':'预约失败'}}</div>
          </div>
          <div class="bottom-bom">
            <img :src="item.areaImg" alt />
            <div>
              <div class="bottom-bom-name">{{item.areaTitle}}</div>
              <span v-if="item.measureArea">{{item.measureArea}}m²，可容纳{{item.capacity}}人</span>
              <div class="bottom-bom-time">预约时间段：</div>
              <div>{{item.applyTime}} {{item.a+'-'+item.b}}</div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <van-loading v-else size="24px" style="text-align:center;margin-top:10px">加载中...</van-loading>
  </div>
</template>

<script>
import { getUserInfo, getSubscribePage } from '@/api/user'
import { Toast } from 'vant'
export default {
  data() {
    return {
      show: true,
      loading: false,
      finished: true,
      finishedtext: '',
      phone: '',
      img: false,
      companyName: '',
      username: '',
      authStatus: '',
      data: [],
      isReviewer: '',
      num: 1,
      flag: false
    }
  },
  created() {},
  methods: {
    go() {
      this.loading = false
      this.finished = true
      this.num = 1
      const token = localStorage.getItem('token')
      if (token) {
        this.flag = true
      } else {
        localStorage.setItem('flag', 0)
        this.$router.push('/login')
      }
    },
    gologin() {
      this.$router.push('/login')
    },
    async onLoad() {
      console.log(11111)
      this.num += 1
      const { data } = await getSubscribePage({
        pageSize: 10,
        pageNum: this.num
      })
      if (data.data.records.length !== 0) {
        data.data.records.forEach(res => {
          res.applyTime = res.startTime.substring(0, 10)
          res.a = res.startTime.substring(11).substring(0, 5)
          res.b = res.endTime.substring(11).substring(0, 5)
        })
        this.data = [...this.data, ...data.data.records]
        this.loading = false
        this.finished = false
      } else {
        this.finished = true
        this.finishedtext = '没有更多了'
      }
    },
    async getSubscribePage() {
      const { data } = await getSubscribePage({
        pageSize: 10,
        pageNum: this.num
      })
      this.data = data.data.records
      if (this.data.length === 0) {
        this.show = false
      } else {
        this.data.forEach(res => {
          res.applyTime = res.startTime.substring(0, 10)
          res.a = res.startTime.substring(11).substring(0, 5)
          res.b = res.endTime.substring(11).substring(0, 5)
        })
      }
      this.finished = false
    },
    async getUserInfo() {
      try {
        const { data } = await getUserInfo()
        if (data.code === 200) {
          this.phone = data.data.phone
          this.img = data.data.avatar
          this.companyName = data.data.companyName
          this.username = data.data.username
          this.authStatus = data.data.authStatus
          if (data.data.isReviewer1 || data.data.isReviewer2) {
            data.data.isReviewer1
              ? (this.isReviewer = data.data.isReviewer1)
              : (this.isReviewer = 2)
          }
        } else if (data.code === 401) {
          Toast('登录已过期')
          localStorage.setItem('token', '')
          setTimeout(() => {
            this.$router.push('/login')
          }, 1000)
        }
      } catch (error) {
        console.log(error)
      }
      if (this.phone) {
        this.getSubscribePage()
      } else {
        this.$router.push('/login')
      }
    },
    goyy() {
      localStorage.setItem('flag', 2)
      this.$router.push(`/audit?isReviewer=${this.isReviewer}`)
    },
    gosh() {
      localStorage.setItem('flag', 2)
      this.$router.push(`/unreviewed?isReviewer=${this.isReviewer}`)
    },
    goaudit() {
      if (this.flag) {
        localStorage.setItem('flag', 2)
        this.$router.push(`/audit?isReviewer=${this.isReviewer}`)
      } else {
        localStorage.setItem('flag', 0)
        this.$router.push('/login')
      }
    },
    goinform(img, phone, companyName, username) {
      if (!companyName) {
        companyName = '无'
      }
      localStorage.setItem('flag', 2)
      this.$router.push(
        `/information?img=${img}&phone=${phone}&companyName=${companyName}&username=${username}`
      )
    },
    goRecord() {
      if (this.flag) {
        localStorage.setItem('flag', 2)
        this.$router.push('/record')
      } else {
        this.$router.push('/login')
      }
    },
    goSubmit() {
      if (this.flag) {
        localStorage.setItem('flag', 2)
        this.$router.push('/submitopinions')
      } else {
        this.$router.push('/login')
      }
    },
    goAuthentication() {
      if (this.flag) {
        localStorage.setItem('flag', 2)
        this.$router.push(`/authentication?phone=${this.phone}`)
      } else {
        this.$router.push('/login')
      }
    },
    goBookingdetails(id) {
      localStorage.setItem('flag', 2)
      this.$router.push(
        `/bookingdetails?id=${id}&isReviewer=${this.isReviewer}`
      )
    }
  }
}
</script>

<style lang="less" scoped>
.notoken {
  font-size: 16px;
  margin-left: 8px;
  font-weight: bold;
}
.content {
  // height: 100vh;
  .top {
    width: 94%;
    margin-left: 3%;
    position: relative;
    margin-top: 15px;
    .t-box {
      display: flex;
      align-items: center;
      width: 100%;
      background-color: #fff;
      height: 100px;
      .name-box {
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        img {
          margin-left: 5px;
          margin-top: 3px;
          width: 12px;
          height: 12px;
        }
      }
      .name {
        font-size: 14px;
        font-weight: bold;
      }
      .headimg {
        border-radius: 50% 50%;
        width: 60px;
        height: 60px;
        margin: 0px 10px;
      }
      .phone {
        font-size: 12px;
        color: #999;
      }
    }
    > img {
      top: 20px;
      right: -3px;
      height: 34px;
      position: absolute;
    }
    .b-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15vw 15px;
      background-color: #fff;
      div {
        border: none;
        outline: none;
        width: 90px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        color: #fff;
        text-align: center;
        background: #f8892f;
        background: -webkit-linear-gradient(#f8892f, #ffab67);
        background: -o-linear-gradient(#f8892f, #ffab67);
        background: -moz-linear-gradient(#f8892f, #ffab67);
        background: linear-gradient(#f8892f, #ffab67);
        border-radius: 15px;
      }
      .audit {
        background: #2183f3;
        background: -webkit-linear-gradient(#2183f3, #82bbff);
        background: -o-linear-gradient(#2183f3, #82bbff);
        background: -moz-linear-gradient(#2183f3, #82bbff);
        background: linear-gradient(#2183f3, #82bbff);
      }
    }
  }
  .opinion {
    margin-top: 10px;
    background-color: #fff;
    height: 70px;
    width: 94%;
    margin-left: 3%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #333;
    padding: 0 20px;
    box-sizing: border-box;
    .center {
      height: 15px;
      border: 0.5px solid rgba(210, 210, 210, 0.993);
    }
    > div {
      display: flex;
      align-items: center;
    }
    span {
      font-size: 12px;
      color: #999;
    }
    img {
      height: 27px;
      width: 27px;
      margin-right: 10px;
    }
  }
  .all {
    margin: 14px 0;
    font-size: 14px;
    width: 94%;
    margin-left: 3%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .all-top {
      display: flex;
      align-items: center;
      .yellow {
        margin-right: 10px;
        height: 15px;
        width: 4px;
        border-radius: 2px;
        background-color: #f8892f;
      }
    }

    .bom {
      display: flex;
      align-items: center;
      .all-bom {
        margin-right: 8px;
        color: #999;
      }
    }
    img {
      height: 12px;
      width: 10px;
      margin-top: 1px;
    }
  }
  .bottom {
    box-shadow: 0 0 4px #cccccc;
    margin-bottom: 15px;
    height: 150px;
    font-size: 14px;
    width: 94%;
    margin-left: 3%;
    background-color: #fff;
    padding: 9px 10px;
    box-sizing: border-box;
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .bottom-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .sta {
        color: #2f92f8;
      }
      .sta2 {
        color: #f8892f;
      }
    }
    .bottom-bom {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #999;
      .bottom-bom-name {
        font-size: 16px;
        color: #333333;
        margin-top: 5px;
        margin-bottom: 8px;
      }
      .bottom-bom-time {
        color: #333;
        margin-top: 16px;
        margin-bottom: 5px;
      }
    }
    img {
      margin-right: 12px;
      width: 130px;
      height: 100px;
    }
  }
  #orange {
    color: #f8892f;
  }
  #black {
    color: #9d9d9d;
  }
}
</style>
