import Vue from 'vue'
import VueRouter from 'vue-router'
import Venue from '../views/venue.vue'
import Appointment from '../views/appointment/index.vue'
import Panorama from '../views/panorama.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'venue',
    component: () => import('../views/venue.vue'),
    meta: { title: '全景' }
  },
  {
    path: '/ifm',
    name: 'ifm',
    component: () => import('../views/ifm.vue'),
    meta: { title: '全景' }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/register/index.vue')
  },
  {
    path: '/venue',
    name: 'venue',
    component: Venue,
    meta: { title: '全景' }
  },
  {
    path: '/appointment',
    name: 'appointment',
    component: Appointment,
    meta: { title: '预约' }
  },
  {
    path: '/panorama',
    name: 'panorama',
    component: Panorama,
    meta: { title: '场馆' }
  },
  {
    path: '/booked',
    name: 'booked',
    component: () => import('../views/booked/index.vue'),
    meta: { title: '立即预约' }
  },
  {
    path: '/audit',
    name: 'audit',
    component: () => import('../views/audit/index.vue'),
    meta: { title: '预约审核' }
  },
  {
    path: '/information',
    name: 'information',
    component: () => import('../views/information/index.vue'),
    meta: { title: '用户信息' }
  },
  {
    path: '/password',
    name: 'password',
    component: () => import('../views/password/index.vue'),
    meta: { title: '修改密码' }
  },
  {
    path: '/record',
    name: 'record',
    component: () => import('../views/record/index.vue'),
    meta: { title: '意见记录' }
  },
  {
    path: '/submitopinions',
    name: 'submitopinions',
    component: () => import('../views/submitopinions/index.vue'),
    meta: { title: '提交意见' }
  },
  {
    path: '/authentication',
    name: 'authentication',
    component: () => import('../views/authentication/index.vue'),
    meta: { title: '认证为园区用户' }
  },
  {
    path: '/bookingdetails',
    name: 'bookingdetails',
    component: () => import('../views/bookingDetails/index.vue'),
    meta: { title: '预约详情' }
  },
  {
    path: '/visit',
    name: 'visit',
    component: () => import('../views/visit/index.vue'),
    meta: { title: '参观预约' }
  },
  {
    path: '/unreviewed',
    name: 'unreviewed',
    component: () => import('../views/unreviewed/index.vue'),
    meta: { title: '认证审核列表' }
  },
  {
    path: '/upphone',
    name: 'upphone',
    component: () => import('../views/upphone/index.vue'),
    meta: { title: '修改手机号' }
  },
  {
    path: '/textfield',
    name: 'textfield',
    component: () => import('../views/textField/index.vue')
  }
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  // if (to.meta.title) {
  //   document.title = to.meta.title
  // } else {
  //   document.title = 'VR'
  // }
  if (to.path === '/appointment') {
    next()
    document.documentElement.scrollTop = 0
  } else {
    next()
  }
})

export default router
