import Vue from 'vue'
import Vant from 'vant'
import 'vant/lib/index.css'
import App from './App.vue'
import router from './router'
import vueWechatTitle from 'vue-wechat-title'
// 引入vant
// import { Tabbar, TabbarItem } from "vant";
// Vue.use(Tabbar).use(TabbarItem);

Vue.use(Vant)
Vue.use(vueWechatTitle)

Vue.config.productionTip = false

new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app')
