import req from '@/api/request'

// 注册
export function register(query) {
  return req.post('/register', query)
}

// 获取opid
export function getWechatCode(query) {
  return req.post('/getWechatCode', query)
}

// 获取验证码
export function sms(query) {
  return req.post('/sms', query)
}

// 登陆
export function login(query) {
  return req.post('/login', query)
}

// 用户信息
export function getUserInfo(query) {
  return req.post('/user/getUserInfo', query)
}

// 预约记录分页
export function getSubscribePage(query) {
  return req.post('/order/getSubscribePage', query)
}

// 场地预约分页
export function getAreaSubscribePage(query) {
  return req.post('/area/getAreaSubscribePage', query)
}

// 预约记录详情
export function getSubscribeDetail(query) {
  return req.post('/order/getSubscribeDetail', query)
}

// 意见记录
export function getFeedbackPage(query) {
  return req.post('/feedback/getFeedbackPage', query)
}

// 修改登录密码
export function updatePassword(query) {
  return req.post('/user/updatePassword', query)
}

// 修改用户信息
export function updateUserInfo(query) {
  return req.post('/user/updateUserInfo', query)
}

// 参观预约列表
export function getAreaVisitPage(query) {
  return req.post('/area/getAreaVisitPage', query)
}

// 参观预约详情
export function getAreaVisitDetail(query) {
  return req.post('/area/getAreaVisitDetail', query)
}

// 预约参观
export function subscribeVisit(query) {
  return req.post('/order/subscribeVisit', query)
}

// 预约时间段
export function getSubscribeTime(query) {
  return req.post('/order/getSubscribeTime', query)
}

// 文件上传
export function file(query) {
  return req.post('/file', query)
}

// 意见提交
export function commitFeedback(query) {
  return req.post('/feedback/commitFeedback', query)
}

// 用户认证
export function commitAuth(query) {
  return req.post('/user/commitAuth', query)
}

// 获取预约类型
export function getSubscribeTypeList(query) {
  return req.post('/order/getSubscribeTypeList', query)
}

// 获取场地预约详情
export function getAreaSubscribeDetail(query) {
  return req.post('/area/getAreaSubscribeDetail', query)
}

// 预约场地
export function subscribeArea(query) {
  return req.post('/order/subscribeArea', query)
}

// 审核员数据
export function getSubscribeReviewDetail(query) {
  return req.post('/order/getSubscribeReviewDetail', query)
}

// 审核员数据
export function getSubscribeReviewPage(query) {
  return req.post('/order/getSubscribeReviewPage', query)
}

// 预约审核
export function reviewSubscribe(query) {
  return req.post('/order/reviewSubscribe', query)
}

// 预约审核
export function getReviewPage(query) {
  return req.post('/user/getReviewPage', query)
}

// 审核认证
export function reviewAuth(query) {
  return req.post('/user/reviewAuth', query)
}

// 修改手机号
export function updatePhone(query) {
  return req.post('/user/updatePhone', query)
}

// 忘记密码
export function resetPassword(query) {
  return req.post('/resetPassword', query)
}

// 取消预约
export function cancelSubscribe(query) {
  return req.post('/order/cancelSubscribe', query)
}
