<template>
  <van-tabs v-model="active" @change="swithTab">
    <van-tab>
      <template #title>
        <span :class="active===0?'dd':''">场地预约</span>
      </template>
      <div v-if="tabsData.length" class="content">
        <van-list
          :immediate-check="true"
          v-model="loading"
          :finished="finished"
          :finished-text="finishedtext"
          @load="onLoad"
        >
          <div class="box" v-for="item in tabsData" :key="item.id">
            <div class="top">
              <img class="img1" @click="gofield(0,item,'1')" :src="item.areaImg" loading="lazy" alt />
              <div class="top-phone" @click="tel(item.areaPhone)">
                <img class="phone" src="@/assets/image/phone.png" alt />
              </div>
              <div class="top-ck" @click="govneue(item.vrLink)">
                <img src="@/assets/image/地图.png" alt />
                <span>查看全景</span>
              </div>
            </div>
            <div class="bottom">
              <div class="left">
                <div class="title">{{item.areaTitle}}</div>
                <div class="left-bom">
                  <img src="@/assets/image/时间.png" alt />
                  <div class="time">开放时间: {{item.openStartTime}}-{{item.openEndTime}}</div>
                </div>
              </div>
              <div class="but" @click="appoinment(item.id)">立即预约</div>
            </div>
          </div>
        </van-list>
      </div>
      <van-loading v-else size="24px" style="text-align:center;margin-top:10px">加载中...</van-loading>
      <van-overlay :show="show" @click="show = false">
        <div class="wrapper" @click.stop>
          <div class="block">
            <span>
              场地仅供本园区用户使用，请认证本园区
              用户后再进行预约。
            </span>
            <div class="block-but">
              <div class="block-but-brz" @click="show = false">暂不认证</div>
              <div class="block-but-rz" @click="gorz">我要认证</div>
            </div>
          </div>
        </div>
      </van-overlay>
    </van-tab>
    <van-tab>
      <template #title>
        <span :class="active===1?'dd':''">参观预约</span>
      </template>
      <div class="content" v-if="data.length">
        <van-list
          :immediate-check="true"
          v-model="loading1"
          :finished="finished1"
          :finished-text="finishedtext1"
          @load="onLoad1"
        >
          <div class="box" v-for="item in data" :key="item.id">
            <div class="top">
              <img class="img1" @click="gofield(1,item,'2')" :src="item.areaImg" alt />
              <div class="top-phone" @click="tel(item.areaPhone)">
                <img class="phone" src="@/assets/image/phone.png" alt />
              </div>
              <div class="top-ck" @click="govneue()">
                <img src="@/assets/image/地图.png" alt />
                <span>查看全景</span>
              </div>
            </div>
            <div class="bottom">
              <div class="left">
                <div class="title">{{item.areaTitle}}</div>
                <div class="left-bom">
                  <img src="@/assets/image/时间.png" alt />
                  <div class="time">开放时间: {{item.openStartTime}}-{{item.openEndTime}}</div>
                </div>
              </div>
              <div class="but" @click="govisit(item.id)">立即预约</div>
            </div>
          </div>
        </van-list>
      </div>
      <van-loading v-else size="24px" style="text-align:center;margin-top:10px">加载中...</van-loading>
    </van-tab>
    <van-tab>
      <template #title>
        <span :class="active===2?'dd':''">我的/意见</span>
      </template>
      <My ref="child" />
    </van-tab>
  </van-tabs>
</template>

<script>
import My from './component/my.vue'
import { getAreaSubscribePage, getAreaVisitPage, getUserInfo } from '@/api/user'
import { Toast } from 'vant'

export default {
  components: {
    My
  },
  data() {
    return {
      finishedtext1: '',
      finishedtext: '',
      loading: false,
      finished: true,
      loading1: false,
      finished1: true,
      active: 0,
      show: false,
      tabsData: [],
      data: [],
      authStatus: '',
      size: 10,
      num: 1,
      num1: 1,
      phone: '',
      flag: false
    }
  },
  created() {
    this.active = Number(localStorage.getItem('flag'))
  },
  watch: {
    active(newVal) {
      if (newVal === 2) {
        this.$nextTick(() => {
          this.$refs.child.go()
          this.$refs.child.getUserInfo()
        })
      }
    }
  },
  mounted() {
    this.num = 1
    this.tabsData = []
    this.num1 = 1
    this.data = []
    if (this.active !== 2) {
      this.getdata()
    }
  },
  methods: {
    gofield(a, b, c) {
      localStorage.setItem('flag', a)
      localStorage.setItem('title', b.areaTitle)
      this.$router.push(`/textfield?id=${b.id}&flag=${c}`)
    },
    govneue(row) {
      console.log(row)
      localStorage.setItem('flag', 0)
      if (row) {
        this.$router.push(`/ifm?link=${row}`)
      } else {
        this.$router.push('/venue')
      }
    },
    tel(number) {
      window.location.href = 'tel:' + number
    },
    async onLoad1() {
      this.num1 += 1
      const { data } = await getAreaVisitPage({
        pageSize: this.size,
        pageNum: this.num1
      })
      if (data.data.records.length !== 0) {
        this.data = [...this.data, ...data.data.records]
        this.loading1 = false
        this.finished1 = false
      } else {
        this.finished1 = true
        this.finishedtext1 = '没有更多了'
      }
    },
    async onLoad() {
      this.num += 1
      const { data } = await getAreaSubscribePage({
        pageSize: this.size,
        pageNum: this.num
      })
      if (data.data.records.length !== 0) {
        this.tabsData = [...this.tabsData, ...data.data.records]
        this.loading = false
        this.finished = false
      } else {
        this.finished = true
        this.finishedtext = '没有更多了'
      }
    },
    async getUserInfo() {
      const { data } = await getUserInfo()
      if (data.code === 200) {
        this.authStatus = data.data.authStatus
        this.phone = data.data.phone
        console.log(this.phone)
      }
      this.getAreaSubscribePage()
      this.getAreaVisitPage()
    },
    getdata() {
      this.getUserInfo()
    },
    async govisit(id) {
      const token = localStorage.getItem('token')
      localStorage.setItem('flag', 1)
      if (token) {
        this.$router.push(`/visit?id=${id}`)
      } else {
        Toast('请先登录')
        this.$router.push('/login')
      }
    },
    async swithTab(row) {
      if (row === 1) {
        this.show = false
        this.num1 = 1
        this.data = []
        this.getAreaVisitPage()
      } else if (row === 0) {
        this.num = 1
        this.tabsData = []
        const { data } = await getUserInfo()
        if (data.code === 200) {
          this.authStatus = data.data.authStatus
          this.phone = data.data.phone
        }
        this.getAreaSubscribePage()
      }
    },
    async getAreaVisitPage() {
      const { data } = await getAreaVisitPage({
        pageSize: 10,
        pageNum: this.num1
      })
      this.data = data.data.records
      this.finished1 = false
    },
    async getAreaSubscribePage() {
      const { data } = await getAreaSubscribePage({
        pageSize: this.size,
        pageNum: this.num
      })
      this.tabsData = [...this.tabsData, ...data.data.records]
      this.finished = false
    },
    async appoinment(row) {
      const token = localStorage.getItem('token')
      localStorage.setItem('flag', 0)
      if (token && this.authStatus === 3) {
        this.$router.push(`/booked?id=${row}`)
      } else if (!token) {
        Toast('请先登录')
        this.$router.push('/login')
      } else {
        this.show = true
      }
    },
    gorz() {
      this.$router.push(`/authentication?phone=${this.phone}`)
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-tabs__content {
  padding-bottom: 65px;
  padding-top: 44px;
  // height: 100vh;
}
/deep/ .van-overlay {
  z-index: 3;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  z-index: 9999;
}
.block {
  box-sizing: border-box;
  padding: 4vh 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 75px;
  width: 80%;
  height: 24vh;
  background-color: #fff;
  border-radius: 15px;
  span {
    font-size: 14px;
    color: #333333;
  }
  .block-but {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    font-size: 15px;
    width: 100%;
    .block-but-rz {
      width: 35vw;
      height: 40px;
      color: #fff;
      line-height: 40px;
      background-color: #f8892f;
      border-radius: 20px;
    }
    .block-but-brz {
      border: 0.5px #e2e2e2 solid;
      width: 25vw;
      height: 38px;
      color: #999999;
      line-height: 38px;
      background-color: #ffffff;
      border-radius: 20px;
    }
  }
}
/deep/ .dd {
  font-size: 15px !important;
  color: #f8892f;
}
/deep/ .van-tabs__line {
  background-color: #f8892f;
  width: 30px;
}
/deep/ .van-tabs__wrap {
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 2;
}
.content {
  background-color: #f9f9f9;
  width: 100%;
}
.box {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  border-radius: 12px;
  box-shadow: 0 0 4px #cccccc;
  background-color: #fff;
  width: 94%;
  margin-left: 3%;
  height: 247px;
  overflow: hidden;
  .top {
    position: relative;
    .top-ck {
      position: absolute;
      bottom: 10%;
      left: 3%;
      display: flex;
      background: rgba(0, 0, 0, 0.4);
      color: #fff;
      font-size: 14px;
      line-height: 14px;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      height: 23px;
      width: 90px;
      img {
        width: 12px;
        height: 12px;
        margin-right: 5px;
      }
    }
    .top-phone {
      border: none;
      outline: none;
      height: 38px;
      width: 38px;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 50% 50%;
      position: absolute;
      bottom: 6%;
      right: 3%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
  .img1 {
    width: 100%;
    height: 173px;
  }
  .bottom {
    height: 74px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 15px 10px 15px;
    box-sizing: border-box;
    .left {
      display: flex;
      flex-direction: column;
      align-self: left;
      .left-bom {
        display: flex;
        align-items: center;
      }
      img {
        width: 13px;
        height: 13px;
        margin-right: 3px;
      }
      .title {
        font-size: 18px;
        color: #333333;
        font-weight: bold;
        margin-bottom: 12px;
      }
      .time {
        font-size: 14px;
        color: #969696;
      }
    }
  }
  .but {
    font-size: 14px;
    border-radius: 16px;
    width: 100px;
    height: 34px;
    background-color: #f8892f;
    color: #fff;
    line-height: 34px;
    text-align: center;
    border: none;
    outline: none;
  }
}
</style>
