<template>
  <div>
    <iframe class="box" seamless src="https://hqhrpark.dhqfz.com.trysem.cn/changguan/"></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      src: ''
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  border: none;
  width: 100vw;
  height: calc(100vh - 50px);
}
</style>
